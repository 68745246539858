import React from 'react';
import NextImage from 'next/image';
import { Box, Text, Button } from '@chakra-ui/react';

import { AppBar } from '@/components';
import ErrorImage from '@/assets/images/error.png';
import { useRouter } from 'next/router';

interface ErrorTryAgainProps {
  onTryAgain: () => void;
  showBack?: boolean;
  isFullScreen?: boolean;
}

const ErrorTryAgain: React.FC<ErrorTryAgainProps> = ({ onTryAgain, showBack = false, isFullScreen = false }) => {
  const router = useRouter();

  return (
    <>
      {showBack && <AppBar title="" showCart={false} showBorder={false} showBack />}
      <Box
        paddingTop={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        {...(isFullScreen && { height: '80vh' })}
      >
        <NextImage src={ErrorImage} alt="error-image" width={300} height={250} placeholder="blur" />
        <Box width="xs" marginTop={5}>
          <Text color="#4a4a4a" textAlign="center" fontSize="sm" fontWeight="bold" marginBottom="2">
            Halaman tidak bisa diakses
          </Text>
          <Text color="#4a4a4a" textAlign="center" fontSize="sm">
            Maaf tapi halaman ini tidak bisa diakses, silahkan coba lagi atau kembali ke halaman sebelumnya
          </Text>
        </Box>
        <Box marginTop={4} width="xs">
          <Button
            background="primary.default"
            color="white"
            rounded="full"
            border="1px"
            paddingX={5}
            marginTop={2}
            width="full"
            _hover={{ background: 'primary.default' }}
            onClick={() => router.reload()}
          >
            <Text fontSize="sm">Coba lagi</Text>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ErrorTryAgain;
