/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */

import {
  deleteFromCart,
  getCart,
  getCartCount,
  postAddToCart,
  postPotentialReward,
  postPreCheckout2,
  putChangeCart,
} from '@/lib/api/order';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import useEventLog from '@/lib/hooks/utils/useEventLog';
import { useAppDispatch, useAppSelector } from '@/store';
import { removeAddressIntro } from '@/store/address_intro/action';
import { removePenalty } from '@/store/penalty/action';
import usePreCheckout from './precheckout';
import { saveSelectedCart } from '@/store/cart/action';
import { savePreCheckoutNew } from '@/store/checkout/action';

const useCart = () => {
  const dispatch = useAppDispatch();
  const selectedCartData = useAppSelector((state) => state.CartStore?.data);
  const addressCheckoutData = useAppSelector((state) => state.AddressCheckoutStore?.data);
  const preCheckoutNewData = useAppSelector((state) => state.PreCheckoutNewStore?.data);
  const { LOGS, writeLog } = useEventLog();
  const { updateTempPreCheckoutInit } = usePreCheckout();
  // API
  const {
    mutate: mutationProductAddToCart,
    isError: isErrorAddToCart,
    reset: resetStateAddToCart,
  } = useMutation(postAddToCart);
  const {
    mutate: mutationDeleteFromCart,
    isError: isErrorDeleteFromCart,
    reset: resetStateDeleteFromCart,
  } = useMutation(deleteFromCart);
  const {
    mutate: mutationChangeCart,
    isError: isErrorChangeCart,
    reset: resetStateChangeCart,
  } = useMutation(putChangeCart);
  const { mutate: mutationPotentialReward } = useMutation(postPotentialReward);
  const onGetCartCount = ({ option }: { option?: UseQueryOptions<unknown, unknown, Cart.CartCountProps, any> }) => {
    return useQuery(['cartCount'], () => getCartCount(), option);
  };
  const onGetCart = ({ option }: { option?: UseQueryOptions<unknown, unknown, Cart.CartItemProps[], any> }) => {
    return useQuery(['cart'], () => getCart(), option);
  };
  const onPostProductAddToCart = (request: Cart.AddToCartRequest, callback: (message: string) => void) => {
    mutationProductAddToCart(request, {
      onSuccess: (response) => {
        if (response !== undefined) callback(response.message_client);
      },
      onError: () => {
        resetStateAddToCart();
      },
    });
  };
  const onDeleteProductFromCart = (
    itemIndex: number,
    productIndex: number,
    itemID: string,
    callback: (message: string) => void,
  ) => {
    mutationDeleteFromCart(itemID, {
      onSuccess: (response) => {
        if (response !== undefined) {
          // Setup Analytics Do Action Delete From Cart
          writeLog(LOGS.CLICK.CLICK_CART_DELETE_ITEM, cart[itemIndex].products[productIndex]);

          const suppliers = [...cart];
          const tempSKU = [...suppliers[itemIndex].products];
          tempSKU.splice(productIndex, 1);
          suppliers[itemIndex].products = tempSKU;
          if (suppliers[itemIndex].products.length === 0) {
            suppliers.splice(itemIndex, 1);
          }
          setCart(suppliers);
          callback(response.message_client);
        }
      },
      onError: () => {
        resetStateDeleteFromCart();
      },
    });
  };
  const onPutChangeCart = (
    itemIndex: number,
    productIndex: number,
    additional: number,
    request: Cart.UpdateCartRequest,
  ) => {
    mutationChangeCart(request, {
      onSuccess: (response) => {
        if (response !== undefined) {
          // Setup Analytics Do Action Change Cart Quantity
          writeLog(LOGS.ON_CHANGE.ON_CHANGE_CART_QUANTITY, request);

          const suppliers = [...cart];
          const tempSKU = [...suppliers[itemIndex].products];
          tempSKU[productIndex].quantity = additional;
          suppliers[itemIndex].products = tempSKU;
          setCart(suppliers);
        }
      },
      onError: () => {
        resetStateChangeCart();
      },
    });
  };
  const onPostPotentialReward = (request: Cart.PotentialRewardReq) => {
    mutationPotentialReward(request, {
      onSuccess: (response) => {
        if (response !== undefined) {
          // Setup Analytics Do Action Potential Reward
          writeLog(LOGS.ON_CHANGE.ON_CHANGE_POTENTIAL_REWARD, request);
          setPotentialReward(response.stars);
        }
      },
    });
  };

  const [cartCount, setCartCount] = useState<number>(0);
  const [reloadCartCount, setReloadCartCount] = useState<boolean>(true);

  const [cart, setCart] = useState<Cart.CartItemProps[]>([]);
  const [reloadCart, setReloadCart] = useState<boolean>(true);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [selectedCartItems, setSelectedCartItems] = useState<number[][]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [potentialReward, setPotentialReward] = useState<number>(0);
  const [type, setType] = useState<string>('');

  useEffect(() => {
    const temp: number[][] = [];
    const tempCart: Cart.CartItemProps[] = [];
    cart.forEach((it) => {
      temp.push([]);
      tempCart.push(it);
    });
    setSelectedCartItems(temp);
  }, [cart]);

  useEffect(() => {
    setTotalPrice(0);
  }, []);

  useEffect(() => {
    if (totalPrice !== undefined && totalPrice > 0) {
      onPostPotentialReward({
        amount: totalPrice,
      });
    } else setPotentialReward(0);
  }, [totalPrice]);

  useEffect(() => {
    // Check Reflect Select All
    reflectSelectAll();

    let tempTotal = 0;
    selectedCartItems.forEach((supplier, iSupplier) => {
      supplier.forEach((product) => {
        const isExists = cart[iSupplier].products.findIndex((it) => it.sku_id === product);
        if (isExists > -1)
          tempTotal += cart[iSupplier].products[isExists].price * cart[iSupplier].products[isExists].quantity;
      });
    });
    setTotalPrice(tempTotal);
  }, [selectedCartItems]);

  useEffect(() => {
    const filteredCartProducts = cart.flatMap((c) => c.products);
    const flatSelectedCart = selectedCartItems.flatMap((c) => c);

    if (flatSelectedCart.length > 0) {
      const filteredCart = filteredCartProducts.filter((p) => flatSelectedCart.includes(p.sku_id));

      dispatch(saveSelectedCart(filteredCart));

      let isHardware = false;
      for (const c of filteredCart) {
        if (!c.is_software) {
          isHardware = true;
          break;
        }
      }

      if (isHardware) setType('bundle');
      else setType('software');
    }
  }, [cart, selectedCartItems]);

  const setCartCountData = (count: number) => {
    setCartCount(count);
  };

  const updateReloadCartCount = (status: boolean) => {
    setReloadCartCount(status);
  };

  const setCartData = (items: Cart.CartItemProps[]) => {
    setCart(items);
  };

  const updateReloadCart = (status: boolean) => {
    setReloadCart(status);
  };

  const reflectSelectAll = () => {
    let status = true;
    if (cart.length > 0) {
      cart.forEach((it, i) => {
        const temp = selectedCartItems?.[i]?.length == it.products.length;
        if (!temp) status = false;
      });
    } else {
      status = false;
    }

    if (status !== isSelectAll) setIsSelectAll(status);
  };

  // When Select All
  const updateSelectAll = (status: boolean) => {
    const suppliers: number[][] = [];
    cart.forEach((it) => {
      if (status) {
        const temp: number[] = [];
        it.products.forEach((itProduct) => {
          if (it.status === 'live' && itProduct.status === 'available' && itProduct.stock > 0)
            temp.push(itProduct.sku_id);
        });
        suppliers.push(temp);
      } else suppliers.push([]);
    });
    setSelectedCartItems(suppliers);
    setIsSelectAll(status);

    if (status) {
      // Setup Analytics Do Action Delete From Cart
      writeLog(LOGS.CLICK.CLICK_CART_SELECT_ALL, {
        suppliers_sku_ids: suppliers,
      });
    }
  };

  // When Select Only Supplier
  const updateSelectItems = (indexSupplier: number, isSelected: boolean) => {
    const suppliers = [...selectedCartItems];
    const temp: number[] = [];
    cart[indexSupplier]?.products.forEach((it) => {
      if (cart[indexSupplier].status === 'live' && it.status === 'available' && it.stock > 0) temp.push(it.sku_id);
    });
    suppliers[indexSupplier] = isSelected ? temp : [];
    setSelectedCartItems(suppliers);

    if (isSelected) {
      // Setup Analytics Do Action Delete From Cart
      writeLog(LOGS.CLICK.CLICK_CART_SELECT_SUPPLIER, {
        supplier_sku_ids: temp,
      });
    }
  };

  // Select Only Product
  const updateSelectItemProduct = (indexSupplier: number, indexSKU: number, skuID: number) => {
    const suppliers = [...selectedCartItems];
    const isExists = suppliers?.[indexSupplier]?.findIndex((it) => it === skuID);
    if (isExists > -1) {
      // Exists Do RemoveAction
      const temp = [...suppliers[indexSupplier]];
      temp.splice(isExists, 1);
      suppliers[indexSupplier] = temp;
    } else {
      const temp = [...suppliers[indexSupplier]];
      temp.push(skuID);
      suppliers[indexSupplier] = temp;
    }

    setSelectedCartItems(suppliers);

    if (isExists) {
      // Setup Analytics Do Action Delete From Cart
      writeLog(LOGS.CLICK.CLICK_CART_SELECT_ITEM, {
        sku_id: skuID,
      });
    }
  };

  const getFinalCart = () => {
    dispatch(removePenalty());
    dispatch(removeAddressIntro());

    const items: Cart.CartItemProps[] = [];
    selectedCartItems.forEach((supplier, iSupplier) => {
      const products: Cart.ProductProps[] = [];
      supplier.forEach((product) => {
        const isExists = cart[iSupplier].products.findIndex((it) => it.sku_id === product);
        if (isExists > -1) products.push(cart[iSupplier].products[isExists]);
      });
      if (products.length > 0) {
        items.push({
          ...cart[iSupplier],
          products: products,
        });
      }
    });

    // Setup Analytics Do Action Change Cart Quantity
    writeLog(LOGS.CLICK.CLICK_CART_CHECKOUT, {
      items: items,
    });

    updateTempPreCheckoutInit({
      cart: items,
      address: undefined,
    });
  };

  const { mutate: mutatePostPreCheckout, isLoading: isLoadingPostPreCheckout } = useMutation(postPreCheckout2);

  const onPostPreCheckout = ({ payload, onSuccess, onError }: { payload: any; onSuccess: any; onError?: any }) => {
    mutatePostPreCheckout(payload, {
      onSuccess: (res) => onSuccess(res),
      onError: () => onError(),
    });
  };

  return {
    cartCount,
    reloadCartCount,
    cart,
    reloadCart,
    selectedCartItems,
    totalPrice,
    potentialReward,
    isSelectAll,
    setCartCountData,
    updateReloadCartCount,
    setCartData,
    updateReloadCart,
    updateSelectAll,
    updateSelectItems,
    updateSelectItemProduct,
    getFinalCart,
    onGetCartCount,
    onGetCart,
    onPostProductAddToCart,
    onDeleteProductFromCart,
    onPutChangeCart,
    isErrorAddToCart,
    isErrorDeleteFromCart,
    isErrorChangeCart,
    type,
    selectedCartData,
    addressCheckoutData,
    onPostPreCheckout,
    savePreCheckoutNew,
    preCheckoutNewData,
    isLoadingPostPreCheckout,
  };
};

export default useCart;
