import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import PopupWaVerification from '@/components/popup-wa-verification';
import useFavorite from '@/lib/hooks/app/favorite';
import { RootState } from '@/store';
import { useEffect, useState } from 'react';

function NewUser() {
  const router = useRouter();
  const { user } = useSelector((state: RootState) => state.UserData) as any;

  const [hasVerifiedWhatsapp, setHasVerifiedWhatsapp] = useState(true);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setHasVerifiedWhatsapp(user?.is_whatsapp_verified);
    }
  }, [user]);

  useEffect(() => {
    if (
      router.isReady &&
      Object.keys(user).length > 0 &&
      user?.is_kyc_verified === 'no_request' &&
      user?.is_whatsapp_verified
    ) {
      // router.push('/admin/kyc');
    }
  }, [user?.is_kyc_verified, user?.is_whatsapp_verified, user]);

  const {
    toggleModalWa,
    inputWa,
    openModalWa,
    mode,
    isLoadingSubmitWa,
    isLoadingSubmitToken,
    myOtp,
    handleInputWa,
    onSubmitWa,
    setMode,
    setMyOpt,
    onVerifyOtp,
  } = useFavorite();

  return (
    <>
      {Object.keys(user?.length > 0) && (
        <PopupWaVerification
          disableBtn={inputWa.length < 10}
          isOpen={!hasVerifiedWhatsapp || false}
          mode={mode}
          phoneNumber={inputWa}
          loadingSubmitWa={isLoadingSubmitWa}
          isLoadingSubmitCode={isLoadingSubmitToken}
          myOtp={myOtp}
          onClose={toggleModalWa}
          onChangeText={handleInputWa}
          onSubmitWa={onSubmitWa}
          onBack={() => setMode('input_wa')}
          onSaveOtp={setMyOpt}
          onVerifyOtp={onVerifyOtp}
          withCloseButton={false}
        />
      )}
    </>
  );
}

export default NewUser;
