import axios from 'axios';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';
import { createStandaloneToast } from '@chakra-ui/toast';

const { toast } = createStandaloneToast();

const token: { access_token: string } = JSON.parse(Cookies.get('token') || '{}');

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  withCredentials: false,
  // baseURL: 'https://api-app.staging.olsera.co',
});

const codeStatus = ['ERR_BAD_REQUEST', 'ERR_UNAUTHORIZED', 'ERR_FORBIDDEN', 'ERR_NOT_FOUND', 'ERR_INTERNAL_SERVER'];

if (token) {
  const accessToken = `Bearer ${token.access_token}`;
  axiosInstance.defaults.headers.common['Authorization'] = accessToken;
}

axiosInstance.interceptors.request.use(
  (request) => request,
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);
    if (error.response && codeStatus.includes(error.code)) {
      const disableMessageServer: string[] = ['Referral Not Found'];
      const messageServer: string = error.response.data.error.message_server;

      if (!toast.isActive('error') && !disableMessageServer.includes(messageServer)) {
        toast({
          id: 'error',
          description: error.response.data.message_client || error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          containerStyle: {
            fontSize: 'xs',
          },
        });
      }
    }
    if (error.response && error.response.status === 401) {
      Cookies.remove('token');
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
