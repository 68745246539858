import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { Box } from '@chakra-ui/react';
import { BottomBar } from '@/components';
import useLayout from '@/lib/hooks/utils/useLayout';
import Cookies from 'js-cookie';
import useStorage from '@/lib/hooks/utils/useStorage';
import useMyReferral from '@/lib/hooks/app/admin/useMyReferral';
import InviteMentorPopup from '@/app/Admin/MyReferral/Popup/invite-mentor';
import NewUser from '@/components/new-user';

interface LayoutProp {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProp> = ({ children }): JSX.Element => {
  const router = useRouter();
  const { onGetMyToko, getInitialUserData, getParams } = useLayout();
  const { getFromStorage, saveToStorage } = useStorage();
  const {
    showInvitePopup,
    toggleInvitePoup,
    onSubmitMentor,
    selectedMentor,
    codeInput,
    fetchMentort,
    removeSelectedMentor,
    handleInputCode,
    handleFetchCode,
    getReferralDetail,
    myReferral,
  } = useMyReferral({ enable: false });
  const FIRST_TIME_OPEN = 'isOpenFirstTime';
  const token = Cookies.get('token');

  const params = getParams();
  const showTitle = params?.show_title;
  const fromApps = params?.from;

  React.useEffect(() => {
    if (token) {
      if (router.pathname !== '/admin') {
        onGetMyToko();
      }
      if (
        router.pathname !== '/' &&
        router.pathname !== '/admin/myreferral' &&
        router.pathname !== '/admin/share-referral/template'
      ) {
        getInitialUserData();
      }
      getReferralDetail();
    }
  }, [router.pathname]);

  const handlePopup = async () => {
    const isHaveOpenApp = await getFromStorage('isOpenFirstTime');

    if (isHaveOpenApp || showTitle) {
      if (showInvitePopup) {
        toggleInvitePoup();
      }
      return;
    } else {
      toggleInvitePoup();
      saveToStorage(FIRST_TIME_OPEN, 'true');
    }
  };
  React.useEffect(() => {
    if (token && myReferral?.leader_name === '') {
      handlePopup();
    }
  }, [myReferral]);

  const whiteListBottomBar = ['/', '/favorite', '/order', '/admin', '/mission', '/profile'];

  return (
    <>
      <Head>
        <title>Selleri Apps</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        <meta name="description" content="Selleri apps is reseller website for selling product" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Box
        as="div"
        maxWidth="md"
        marginX="auto"
        marginY="0px"
        // overflowX="hidden"
        minHeight="100vh"
        background="white"
        position="relative"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
        {whiteListBottomBar.includes(router.pathname) && (fromApps !== 'apps' || fromApps !== undefined) && (
          <Box paddingTop={'12'}>
            <BottomBar />
          </Box>
        )}

        {router.pathname !== '/login' && <NewUser />}
        {/* <InviteMentorPopup
          selectedMentor={selectedMentor}
          codeInput={codeInput}
          loading={fetchMentort}
          isShow={showInvitePopup}
          onClose={toggleInvitePoup}
          onSubmit={onSubmitMentor}
          onCancel={removeSelectedMentor}
          onChange={handleInputCode}
          onSearchMentor={handleFetchCode}
        /> */}
      </Box>
    </>
  );
};

export default Layout;
