import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Text } from '@chakra-ui/react';

import HomeIcon from '@/assets/icons/home';
import HeartIcon from '@/assets/icons/heart';
import UserIcon from '@/assets/icons/user';
import FileIcon from '@/assets/icons/file';
import MissionIcon from '@/assets/icons/misi';

const Navigation = (): JSX.Element => {
  const router = useRouter();

  const BottomNavItems = [
    {
      href: '/',
      icon: HomeIcon,
      text: 'Beranda',
    },
    {
      href: '/order',
      icon: FileIcon,
      text: 'Pesanan',
    },
    {
      href: '/mission',
      icon: MissionIcon,
      text: 'Misi',
    },
    {
      href: '/profile',
      icon: UserIcon,
      text: 'Profil',
    },
  ];

  const isCurrentPath = (currentPath: string, targetPath: string) => {
    return currentPath === targetPath || currentPath.split('/')[1] === targetPath;
  };

  return (
    <>
      <Box
        as="div"
        display="flex"
        justifyContent="space-between"
        position="fixed"
        padding={3}
        paddingY={1}
        bottom={0}
        width="full"
        maxWidth="md"
        background="white"
        borderTopWidth="1px"
        borderTopColor="gray.200"
        zIndex={30}
        className="!pb-0"
      >
        <Box as="div" display="flex" justifyContent="space-between" width="100%">
          {BottomNavItems.map((item, index) => (
            <Link href={item.href} passHref key={index}>
              <a>
                <Box
                  as="div"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                >
                  <Box as="div" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <item.icon color={isCurrentPath(router.pathname, item.href) ? '#173C82' : '#7F7F7F'} />
                    <Text
                      as="p"
                      fontSize="11px"
                      color={isCurrentPath(router.pathname, item.href) ? '#173C82' : '#7F7F7F'}
                      mt={1.5}
                    >
                      {item.text}
                    </Text>
                    {isCurrentPath(router.pathname, item.href) && (
                      <div className="w-[61px] h-[4px] bg-primary rounded-t-[100px]" />
                    )}
                  </Box>
                </Box>
              </a>
            </Link>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Navigation;
