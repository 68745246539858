/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */

import {
  getBankList,
  getOrderFinance,
  getRekeningList,
  getRewardDetail,
  getRewardHistory,
  getWithdrawHistory,
  postClaimReward,
  postRekeningCreate,
  postWithdraw,
  putRekeningEdit,
} from '@/lib/api/admin';
import { postRequestOtp, postValidatePIN, postVerifyOtpWithdraw } from '@/lib/api/auth';
import { appVersion } from '@/utils';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import RekeningCreateRequest = Order.RekeningCreateRequest;
import RekeningEditRequest = Order.RekeningEditRequest;

const useFinance = () => {
  // API
  const { mutate: mutationOrderList } = useMutation(getOrderFinance);
  const { mutate: mutationWithdrawHistory } = useMutation(getWithdrawHistory);
  const {
    mutate: mutationRewardHistory,
    isLoading: isLoadingRewardHistory,
    reset: resetStateRewardHistory,
  } = useMutation(getRewardHistory);
  const {
    mutate: mutationRewardDetail,
    isLoading: isLoadingRewardDetail,
    reset: resetStateRewardDetail,
  } = useMutation(getRewardDetail);
  const { mutate: mutationBankList } = useMutation(getBankList);
  const { mutate: mutationRekeningList, isLoading: isLoadingRekeningList } = useMutation(getRekeningList);
  const {
    mutate: mutationRekeningCreate,
    isLoading: isLoadingRekeningCreate,
    reset: resetStateRekeningCreate,
  } = useMutation(postRekeningCreate);
  const {
    mutate: mutationRekeningEdit,
    isLoading: isLoadingRekeningEdit,
    reset: resetStateRekeningEdit,
  } = useMutation(putRekeningEdit);
  const {
    mutate: mutateRequestOtp,
    isLoading: isLoadingRequestOtp,
    reset: resetStateRequestOtp,
  } = useMutation(postRequestOtp);
  const {
    mutate: mutateVerifyOtp,
    isLoading: isLoadingVerifyOtp,
    reset: resetStateVerifyOtp,
  } = useMutation(postVerifyOtpWithdraw);
  const {
    mutate: mutateValidatePIN,
    isLoading: isLoadingValidatePIN,
    reset: resetStateValidatePIN,
  } = useMutation(postValidatePIN);
  const { mutate: mutateWithdraw, isLoading: isLoadingWithdraw, reset: resetStateWithdraw } = useMutation(postWithdraw);
  const {
    mutate: mutateClaimReward,
    isLoading: isLoadingClaimReward,
    reset: resetStateClaimReward,
  } = useMutation(postClaimReward);

  const onPostOrderList = (status: number) => {
    mutationOrderList(status, {
      onSuccess: (response) => {
        if (response !== undefined) {
          setOrderFinance(
            response.map((it) => {
              const currentDate = new Date();
              const expiredDate = it?.complaint_deadline !== undefined ? it?.complaint_deadline : new Date();
              let differentDate = Math.abs(expiredDate.getMilliseconds() - currentDate.getMilliseconds()) / 1000;

              const days = Math.floor(differentDate / 86400);
              differentDate -= days * 86400;

              const hours = Math.floor(differentDate / 3600) % 24;
              differentDate -= hours * 3600;
              const minutes = Math.floor(differentDate / 60) % 60;
              differentDate -= minutes * 60;

              return {
                id: it.id,
                amount: {
                  total: it.total_price,
                  total_earning: it.total_earning,
                  admin_fee: 0,
                  order: 0,
                  order_voucher_deduction: 0,
                  paid: 0,
                  product: 0,
                  shipping: it?.amount?.shipping || 0,
                  total_voucher_deduction: 0,
                  transaction_voucher_deduction: 0,
                },
                created_at: it.created_at,
                expired_at: it.created_at,
                image: it.image,
                last_status: it.last_status,
                name: it.name,
                quantity: it.quantity,
                is_show_countdown: it?.complaint_deadline !== undefined,
                limit_time: `${days} Hari : ${hours} Jam : ${minutes} Menit`,
              };
            }),
          );
        }
      },
    });
  };

  const onPostWithdrawHistory = () => {
    mutationWithdrawHistory(undefined, {
      onSuccess: (response) => {
        if (response !== undefined) {
          setOrderFinanceHistory(response);
        }
      },
    });
  };

  const onPostClaimReward = (
    request: Order.ClaimRewardRequest,
    callback: (message: string, status: boolean) => void,
  ) => {
    mutateClaimReward(request, {
      onSuccess: (response) => {
        if (response !== undefined) {
          callback(response.message_client, response.success);
        }
      },
      onError: () => {
        resetStateClaimReward();
      },
    });
  };

  const onGetBankList = () => {
    mutationBankList(undefined, {
      onSuccess: (response) => {
        if (response !== undefined) {
          setBankList(response);
        }
      },
    });
  };

  const onGetRewardHistory = () => {
    mutationRewardHistory(undefined, {
      onSuccess: (response) => {
        if (response !== undefined) {
          setRewardHistory(response);
        }
      },
      onError: () => {
        resetStateRewardHistory();
      },
    });
  };

  const onGetRewardDetail = (rewardID: number) => {
    mutationRewardDetail(rewardID, {
      onSuccess: (response) => {
        if (response !== undefined) {
          setRewardDetail(response);
        }
      },
      onError: () => {
        resetStateRewardDetail();
      },
    });
  };

  const onGetRekeningList = () => {
    mutationRekeningList(undefined, {
      onSuccess: (response) => {
        if (response !== undefined) {
          setRekeningList(response);
        }
      },
    });
  };

  const onPostRekeningCreate = (data: RekeningCreateRequest, callback: (message: string) => void) => {
    mutationRekeningCreate(data, {
      onSuccess: (response) => {
        if (response !== undefined) {
          callback(response.message_client);
        }
      },
      onError: () => {
        resetStateRekeningCreate();
      },
    });
  };

  const onPutRekening = (data: RekeningEditRequest, callback: (message: string) => void) => {
    mutationRekeningEdit(data, {
      onSuccess: (response) => {
        if (response !== undefined) {
          callback(response.message_client);
        }
      },
      onError: () => {
        resetStateRekeningCreate();
      },
    });
  };

  const onRequestOtp: SubmitHandler<{ email: string; callback?: () => void | undefined }> = ({ email, callback }) => {
    const payload = {
      value: email,
      source: 'withdraw_request',
      type: 'email',
      device_information: {
        app_version: appVersion,
        brand: 'web_apps',
        api_level: '31',
        device_id: 'web_apps',
      },
    };

    mutateRequestOtp(payload, {
      onSuccess: (response) => {
        setTokenOtp(response.data.token);
        if (callback) callback();
      },
      onError: () => {
        resetStateRequestOtp();
      },
    });
  };

  const onValidatePIN: SubmitHandler<{ pin: string; callback: (message?: string) => void | undefined }> = ({
    pin,
    callback,
  }) => {
    mutateValidatePIN(pin, {
      onSuccess: (response) => {
        if (response?.data) callback();
        else callback(response?.message_client);
      },
      onError: () => {
        resetStateValidatePIN();
      },
    });
  };

  const onVerifyOtp = (pin: string, otpValue: string, callback: (message: string, isError: boolean) => void) => {
    const payload = {
      code: otpValue,
      token: tokenOtp,
    };

    mutateVerifyOtp(payload, {
      onSuccess: (response) => {
        if (response.success) {
          // Go To Request Withdraw
          onRequestWithDraw(pin, commisionAmount, referralAmount, callback);
        } else {
          // Show Error Toast
          callback(response.message_client, true);
        }
      },
      onError: () => {
        resetStateVerifyOtp();
      },
    });
  };

  const onRequestWithDraw = (
    pin: string,
    commisionAmount: number,
    referralAmount: number,
    callback: (message: string, isError: boolean) => void,
  ) => {
    mutateWithdraw(
      {
        bank_name: selectedRekening?.bank_details?.name || '',
        commission_amount: commisionAmount,
        commission_referral_amount: referralAmount,
        rekening_number: selectedRekening?.rekening_number || '',
        sales_amount: 0,
        pin: pin,
      },
      {
        onSuccess: (response) => {
          if (response !== undefined) {
            if (response.success) {
              // Success
              callback(response.message_client, false);
            }
          }
        },
        onError: () => {
          resetStateWithdraw();
        },
      },
    );
  };

  const [orderFinance, setOrderFinance] = useState<Order.OrderItemProps[]>([]);
  const [orderFinanceHistory, setOrderFinanceHistory] = useState<Order.OrderFinanceHistory[]>([]);
  const [bankList, setBankList] = useState<Order.BankList[]>([]);
  const [rekeningList, setRekeningList] = useState<Order.RekeningList[]>([]);
  const [rekeningDetail, setRekeningDetail] = useState<Order.RekeningList>();
  const [selectedRekening, setSelectedRekening] = useState<Order.RekeningList>();
  const defaultFormAddRekening = {
    bank_details: null,
    bank_id: -1,
    created_at: '',
    default: false,
    holder_name: '',
    id: -1,
    is_validated: false,
    rekening_number: '',
    status: '',
    updated_at: '',
    user_id: -1,
  };
  const [formAddRekening, setFormAddRekening] = useState<Order.RekeningList>({
    bank_details: null,
    bank_id: -1,
    created_at: '',
    default: false,
    holder_name: '',
    id: -1,
    is_validated: false,
    rekening_number: '',
    status: '',
    updated_at: '',
    user_id: -1,
  });
  const [tokenOtp, setTokenOtp] = useState<string>('');
  const [commisionAmount, setCommissionAmount] = useState<number>(0);
  const [referralAmount, setReferralAmount] = useState<number>(0);
  const [rewardHistory, setRewardHistory] = useState<Order.OrderRewardHistory[]>([]);
  const [rewardDetail, setRewardDetail] = useState<Order.OrderRewardDetail>();

  useEffect(() => {
    if (rekeningList.length > 0) {
      let temp = rekeningList.find((it) => it.default === true);
      if (temp === undefined) {
        temp = rekeningList[0];
      }
      setSelectedRekening(temp);
    }
  }, [rekeningList]);

  const updateOrderFinance = (data: Order.OrderItemProps[]) => {
    setOrderFinance(data);
  };

  const updateSelectedRekening = (data: Order.RekeningList) => {
    setSelectedRekening(data);
  };

  const updateFormAddRekening = (data: Order.RekeningList) => {
    setFormAddRekening(data);
  };

  const updateCommissionAmount = (data: number) => {
    setCommissionAmount(data);
  };
  const updateReferralAmount = (data: number) => {
    setReferralAmount(data);
  };

  return {
    orderFinance,
    orderFinanceHistory,
    bankList,
    rekeningList,
    formAddRekening,
    selectedRekening,
    tokenOtp,
    referralAmount,
    commisionAmount,
    defaultFormAddRekening,
    rewardHistory,
    rewardDetail,
    updateOrderFinance,
    updateFormAddRekening,
    updateCommissionAmount,
    updateReferralAmount,
    updateSelectedRekening,
    onPostOrderList,
    onPostWithdrawHistory,
    onGetBankList,
    onGetRekeningList,
    onPostRekeningCreate,
    isLoadingRekeningCreate,
    onPutRekening,
    isLoadingRekeningEdit,
    onRequestOtp,
    isLoadingRequestOtp,
    onVerifyOtp,
    isLoadingVerifyOtp,
    onRequestWithDraw,
    isLoadingWithdraw,
    onGetRewardHistory,
    isLoadingRewardHistory,
    onGetRewardDetail,
    isLoadingRewardDetail,
    onPostClaimReward,
    isLoadingClaimReward,
    onValidatePIN,
    isLoadingValidatePIN,
    isLoadingRekeningList,
  };
};

export default useFinance;
