/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import type { AppProps } from 'next/app';
import { QueryClientProvider, Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { appWithTranslation, useTranslation } from 'next-i18next';
import NextNProgress from 'nextjs-progressbar';
import { useToast } from '@chakra-ui/react';
import { PersistGate } from 'redux-persist/integration/react';

import { GoogleMapLoader } from '@/components';

import Layout from '@/layouts';

import { theme } from '@/theme.config';
import { store, persistor } from '@/store';

import { setupAnalytics } from '@/configs/firebase';
import '@fontsource-variable/nunito-sans';
import 'swiper/css';
import 'swiper/css/pagination';
import 'styles/globals.css';
import useApp from '@/lib/hooks/utils/useApp';
import { createStandaloneToast } from '@chakra-ui/toast';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const { t } = useTranslation();
  const { ToastContainer } = createStandaloneToast();

  const toast = useToast({
    containerStyle: {
      fontSize: 'xs',
      width: 'xs',
      maxWidth: 'md',
    },
    duration: 5000,
    isClosable: true,
  });
  const { queryClient } = useApp();

  React.useEffect(() => {
    handleConnection();
    setupAnalytics();
  }, []);

  const handleConnection = () => {
    window.addEventListener('offline', () =>
      toast({
        id: 'no-connection',
        description: t('common:no_connection'),
        status: 'info',
      }),
    );
    window.addEventListener('online', () =>
      toast({
        id: 'no-connection',
        description: t('common:live_connection'),
        status: 'success',
      }),
    );
  };

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <QueryClientProvider client={queryClient}>
          {/* @ts-ignore */}
          <Hydrate state={pageProps.dehydratedState}>
            <ChakraProvider theme={theme} resetCSS>
              <NextNProgress
                color="#173C82"
                options={{
                  showSpinner: false,
                }}
              />
              <Layout>
                <Component {...pageProps} />
                <GoogleMapLoader />
              </Layout>
              <ToastContainer />
            </ChakraProvider>
          </Hydrate>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default appWithTranslation(MyApp);
