import React, { useEffect, useState } from 'react';
import { Input, Box, Text, Spinner, useToast, InputGroup, InputLeftElement, Icon, HStack } from '@chakra-ui/react';
import { AiOutlineSearch, AiOutlineShoppingCart } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi';

import Search from './Search';
import { useCart } from '@/lib/hooks/app/product';
import { useLayout } from '@/lib/hooks/utils';
import { useProfile } from '@/lib/hooks/app/admin';
import useEventLog from '@/lib/hooks/utils/useEventLog';
import { useAppDispatch } from '@/store';
import { removeSearchProduct } from '@/store/search_product/action';
import useFavorite from '@/lib/hooks/app/favorite';
import PopupWaVerification from '@/components/popup-wa-verification';

interface AppBarProp {
  type?: 'search' | 'title';
  title?: string;
  showBack?: boolean;
  showCart?: boolean;
  leftComponent?: JSX.Element;
  onBack?: () => void;
  isTransparent?: boolean;
  showBorder?: boolean;
  searchPlaceholder?: string;
  isSearch?: boolean;
  onSearch?: (search: string) => void;
}

const AppBar: React.FC<AppBarProp> = ({
  showBack = false,
  showCart = true,
  title = 'Title AppBar',
  type = 'title',
  leftComponent,
  onBack,
  isTransparent,
  showBorder = true,
  searchPlaceholder = 'Cari Barang',
  isSearch = false,
  onSearch,
}): JSX.Element => {
  const { goBack, isReady, goToPageHook } = useLayout();
  const dispatch = useAppDispatch();

  // Profile
  const { profile } = useProfile();
  const { LOGS, writeLog } = useEventLog();

  // Cart Count
  const { cartCount, reloadCartCount, updateReloadCartCount, setCartCountData, onGetCartCount } = useCart();
  const {
    toggleModalWa,
    openModalWa,
    handleInputWa,
    inputWa,
    onSubmitWa,
    mode,
    setMode,
    isLoadingSubmitWa,
    setMyOpt,
    onVerifyOtp,
    myOtp,
    isLoadingSubmitToken,
  } = useFavorite();
  const [openSearch, setOpenSearch] = React.useState<boolean>(false);
  const toast = useToast();
  const [searchKey, setSearchKey] = useState('');

  // Load Data Cart Count
  const { isFetching: cartCountFetching } = onGetCartCount({
    option: {
      onSuccess: (data) => {
        updateReloadCartCount(false);
        setCartCountData(data?.item_count || 0);
      },
      enabled: isReady() && reloadCartCount && showCart,
    },
  });

  // Show Toast
  const _showToastWarning = (message: string) => {
    toast({
      description: message,
      status: 'warning',
      isClosable: true,
    });
  };

  useEffect(() => {
    if (onSearch) {
      onSearch(searchKey);
    }
  }, [searchKey]);

  return (
    <>
      <Box
        as="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        background={isTransparent ? 'transparent' : 'white'}
        position="sticky"
        top={0}
        width="full"
        maxWidth="md"
        zIndex="overlay"
        paddingX={isTransparent ? '0px' : '3'}
        height={isTransparent ? '0px' : '50px'}
      >
        <Box as="div" display="flex" alignItems="center" justifyContent="center" cursor="pointer" flex={1}>
          {showBack && (
            <Box
              padding={'1'}
              borderRadius={isTransparent ? 'full' : '0'}
              background={isTransparent ? 'primary.default' : 'transparent'}
              as="div"
              marginTop={isTransparent ? '10' : '0'}
              mr={1}
            >
              <FiArrowLeft color={isTransparent ? 'white' : 'black'} size={23} onClick={() => goBack()} />
            </Box>
          )}

          {type === 'title' && (
            <Box as="div" marginX={2} flex={1}>
              <Text fontSize="20px" fontWeight="bold">
                {title}
              </Text>
            </Box>
          )}
          {type === 'search' && (
            <HStack width="full" marginRight={2} marginTop={2}>
              <InputGroup>
                <InputLeftElement>
                  <Icon as={AiOutlineSearch} fontSize={20} textColor={'gray.500'} />
                </InputLeftElement>
                {!isSearch ? (
                  <Input
                    placeholder={searchPlaceholder}
                    readOnly={true}
                    onClick={() => {
                      window.localStorage.setItem('search', '');
                      window.localStorage.removeItem('query');
                      dispatch(removeSearchProduct());
                      goToPageHook('/product/search');
                    }}
                  />
                ) : (
                  <Input
                    placeholder={searchPlaceholder}
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                  />
                )}
              </InputGroup>
            </HStack>
          )}

          {leftComponent && (
            <Box as="div" marginLeft="auto" marginRight={2}>
              {leftComponent}
            </Box>
          )}

          {showCart && (
            <Box
              mt={2}
              as="div"
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginLeft="auto"
              onClick={() => {
                if (profile !== undefined) {
                  if (profile.is_whatsapp_verified) {
                    // Setup Analytics Click Cart
                    writeLog(
                      title.includes('Kategori')
                        ? LOGS.CLICK.CLICK_CART_COUNT_FROM_CATEGORY
                        : type === 'search'
                        ? LOGS.CLICK.CLICK_CART_COUNT_FROM_MARKETPLACE
                        : LOGS.CLICK.CLICK_CART_COUNT_FROM_SUPPLIER_DETAIL,
                    );
                    goToPageHook('/order/cart');
                  } else {
                    toggleModalWa();
                  }
                }
              }}
            >
              <AiOutlineShoppingCart size={25} />
              <Box
                as="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={4}
                height={4}
                marginLeft={-2}
                marginTop={-4}
                borderRadius="full"
                background="white"
                color="black"
                border={'1px'}
                borderColor={'black'}
                fontSize="xs"
              >
                {!cartCountFetching ? cartCount.toString() : <Spinner size={'xs'} />}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {openSearch && <Search handleBack={() => setOpenSearch(false)} />}

      <PopupWaVerification
        disableBtn={inputWa.length < 10}
        isOpen={openModalWa}
        mode={mode}
        phoneNumber={inputWa}
        loadingSubmitWa={isLoadingSubmitWa}
        isLoadingSubmitCode={isLoadingSubmitToken}
        myOtp={myOtp}
        onClose={toggleModalWa}
        onChangeText={handleInputWa}
        onSubmitWa={onSubmitWa}
        onBack={() => setMode('input_wa')}
        onSaveOtp={setMyOpt}
        onVerifyOtp={onVerifyOtp}
      />
    </>
  );
};

export default AppBar;
