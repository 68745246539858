/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import {
  getCommission,
  getProfile,
  getRewardSummary,
  getReferral,
  getMyToko,
  getHoldSaldo,
  getHoldSaldoList,
} from '@/lib/api/admin';
import { getKYCStatus, getReward, postKYC, getResellerHistory, postResellerHistory } from '@/lib/api/admin/profile';
import { postRequestOtp, postSetPin, postVerifyOtp, postVerifyOtpChangeEmailPhone } from '@/lib/api/auth';
import { RootState, useAppSelector } from '@/store';
import { saveTokoData } from '@/store/toko-data/action';
import { saveUserData } from '@/store/user-data/action';
import { appVersion } from '@/utils';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLayout } from '../../utils';
interface resellerHistoryProps {
  userDataHistories: [];
}
const useProfile = () => {
  const dispatch = useDispatch();
  // API
  const [emailValue, setEmailValue] = React.useState<string>('');
  const [loginMode, setLoginMode] = React.useState<'email' | 'otp'>('email');
  const { mutate: mutationMyToko, isLoading: isLoadingMyToko } = useMutation(getMyToko);
  const [resellerHistory, setResellerHistory] = useState<resellerHistoryProps>();
  const {
    mutate: mutateVerifyOtpEmailPhone,
    isLoading: isloadingVerifyEmailPhone,
    isSuccess: isSuccessVerifyEmailPhone,
    reset: resetStateVerifyEmailPhone,
  } = useMutation(postVerifyOtpChangeEmailPhone);
  const {
    mutate: mutateRequestOtp,
    isLoading: isLoadingRequestOtp,
    reset: resetStateRequestOtp,
  } = useMutation(postRequestOtp);
  const {
    mutate: mutateVerifyOtp,
    isLoading: isLoadingVerifyOtp,
    reset: resetStateVerifyOtp,
  } = useMutation(postVerifyOtp);
  const { mutate: mutateUpdatePin, isLoading: isLoadingUpdatePin } = useMutation(postSetPin);
  const { mutate: mutatePostResellerHistory, isLoading: isLoadingPostResellerHistory } =
    useMutation(postResellerHistory);
  const { mutate: mutateHoldSaldo, isLoading: isLoadingHoldSaldo } = useMutation(getHoldSaldo);
  const { mutate: mutateHoldSaldoList, isLoading: isLoadingHoldSaldoList } = useMutation(getHoldSaldoList);
  const {
    mutate: mutateKYCStatus,
    isLoading: isLoadingKYCStatus,
    reset: resetStateKYCStatus,
  } = useMutation(getKYCStatus);
  const {
    mutate: mutateResellerHistory,
    isLoading: isLoadingResellerHistory,
    reset: resetStateResellerHistory,
  } = useMutation(getResellerHistory);
  const { mutate: mutateKYC, isLoading: isLoadingKYC, reset: resetStateKYC } = useMutation(postKYC);
  const onGetProfile = ({ option }: { option?: UseQueryOptions<unknown, unknown, Admin.MyData, any> }) => {
    return useQuery(['profile'], () => getProfile(), option);
  };

  const onPostResellerHistory = (value: string, type: string) => {
    const payload = {
      new_value: value,
      type: type,
    };
    mutatePostResellerHistory(payload, {});
  };
  const { goToPageHook } = useLayout();
  const onVerifyEmailPhoneOtp = (
    value: string,
    otpValue: string,
    type: string | string[] | undefined,
    callback: (message: string, isError: boolean) => void,
  ) => {
    const temp = window.localStorage.getItem('token_otp') || '';
    const payload = {
      code: otpValue,
      token: temp,
      source: type == 'email' ? 'email_update_request' : 'phone_number_update_request',
    };

    mutateVerifyOtpEmailPhone(payload, {
      onSuccess: (response) => {
        if (response.success) {
          onPostResellerHistory(
            window.localStorage.getItem('otp_value') || '',
            type == 'email' ? 'update_email' : 'update_phone_number',
          );
          window.localStorage.setItem('token_otp', '');
          // Go To Request Withdraw
          callback(response.message_client, false);
        } else {
          // Show Error Toast
          callback(response.message_client, true);
        }
      },
      onError: () => {
        resetStateVerifyEmailPhone();
      },
    });
  };
  const onUpdateUserData = (value: string, type: string | string[] | undefined) => {
    const payload = {
      value: value,
      source: type == 'email' ? 'email_update_request' : 'phone_number_update_request',
      type: type == 'email' ? 'email' : 'whatsapp',
      device_information: {
        app_version: appVersion,
        brand: 'web_apps',
        api_level: '31',
        device_id: 'web_apps',
      },
    };
    mutateRequestOtp(payload, {
      onSuccess: (response) => {
        setEmailValue(value);
        setTokenOtp(response.data.token);
        window.localStorage.setItem('token_otp', response.data.token);
        window.localStorage.setItem('otp_value', value);

        onSetLoginMode('otp');
      },
      onError: () => {
        resetStateRequestOtp();
      },
    });
  };

  const onSetLoginMode = (mode: 'email' | 'otp') => {
    setLoginMode(mode);
  };
  const onGetResellerHistory = () => {
    mutateResellerHistory(undefined, {
      onSuccess: (response) => {
        setResellerHistory(response);
      },
      onError: () => {
        resetStateResellerHistory();
      },
    });
  };
  const onGetCommission = ({
    option,
  }: {
    option?: UseQueryOptions<unknown, unknown, Admin.CommisionFinancial, any>;
  }) => {
    return useQuery(['commission'], () => getCommission(), option);
  };
  const onGetRewardSummary = ({ option }: { option?: UseQueryOptions<unknown, unknown, Admin.RewardSummary, any> }) => {
    return useQuery(['reward_summary'], () => getRewardSummary(), option);
  };
  const onGetReward = ({ option }: { option?: UseQueryOptions<unknown, unknown, Admin.Reward[], any> }) => {
    return useQuery(['reward'], () => getReward(), option);
  };
  const onGetReferral = ({ option }: { option?: UseQueryOptions<unknown, unknown, Admin.ReferralDetail, any> }) => {
    return useQuery(['referral'], () => getReferral(), option);
  };
  const onGetMyToko = (callback?: () => void) => {
    mutationMyToko(undefined, {
      onSuccess: (response) => {
        dispatch(
          saveTokoData({
            toko: response.data,
          }),
        );
        if (callback && typeof callback === 'function') callback();
      },
    });
  };

  const onGetKYCStatus = () => {
    mutateKYCStatus(undefined, {
      onSuccess: (response) => {
        setKYCStatus(response);
      },
      onError: () => {
        resetStateKYCStatus();
      },
    });
  };
  const onGetHoldSaldo = () => {
    mutateHoldSaldo(undefined, {
      onSuccess: (response) => {
        setHoldSaldo(response);
      },
    });
  };
  const onGetHoldSaldoList = (status: string) => {
    mutateHoldSaldoList(
      {
        page: 1,
        statusName: status,
      },
      {
        onSuccess: (response) => {
          setHoldSaldoList(response);
        },
      },
    );
  };

  const onPostKYC = (formData: FormData, callback: () => void) => {
    mutateKYC(formData, {
      onSuccess: (response) => {
        setKYCStatus(response);
        callback();
      },
      onError: () => {
        resetStateKYC();
      },
    });
  };

  const onUpdatePIN = () => {
    mutateUpdatePin(pins[0], {
      onSuccess: (response) => {
        updateReloadProfile(true);
        updatePinStep(3);
      },
    });
  };

  const onRequestOtp: SubmitHandler<{ email: string; callback?: () => void | undefined }> = ({ email, callback }) => {
    const payload = {
      value: email,
      source: 'pin_update_request',
      type: 'email',
      device_information: {
        app_version: appVersion,
        brand: 'web_apps',
        api_level: '31',
        device_id: 'web_apps',
      },
    };

    mutateRequestOtp(payload, {
      onSuccess: (response) => {
        setTokenOtp(response.data.token);
        if (callback) callback();
      },
    });
  };

  const onVerifyOtp = (otpValue: string, callback: (message: string, isError: boolean) => void) => {
    const payload = {
      code: otpValue,
      token: tokenOtp,
    };

    mutateVerifyOtp(payload, {
      onSuccess: (response) => {
        if (response.success) {
          // Go To Request Withdraw
          callback(response.message_client, false);
        } else {
          // Show Error Toast
          callback(response.message_client, true);
          resetStateVerifyOtp();
        }
      },
      onError: () => {
        resetStateVerifyOtp();
      },
    });
  };

  // Constant
  // const [profile, setProfile] = useState<Admin.MyData>();
  const profile = useSelector((state: RootState) => state.UserData && state.UserData.user) as Admin.MyData;
  const [reloadProfile, setReloadProfile] = useState<boolean>(true);
  const [commission, setCommission] = useState<Admin.CommisionFinancial>();
  const [reloadCommission, setReloadCommission] = useState<boolean>(true);
  const [reward, setReward] = useState<Admin.Reward[]>([]);
  const [rewardSummary, setRewardSummary] = useState<Admin.RewardSummary>();
  const [reloadRewardSummary, setReloadRewardSummary] = useState<boolean>(true);
  const [reloadReward, setReloadReward] = useState<boolean>(true);
  const [referral, setReferral] = useState<Admin.ReferralDetail>();
  const [reloadReferral, setReloadReferral] = useState<boolean>(true);
  const [pinChangeStep, setPinChangeStep] = useState<number>(0);
  const [pins, setPins] = useState<string[]>(['', '']);
  const [tokenOtp, setTokenOtp] = useState<string>('');
  const [kycStatus, setKYCStatus] = useState<Admin.KYCStatus>();
  const myToko = useAppSelector((state) => state.MyToko);
  const [holdSaldo, setHoldSaldo] = useState<Order.HoldSaldoAmount>();
  const [holdSaldoList, setHoldSaldoList] = useState<Order.HoldSaldoItem[]>();

  useEffect(() => {
    if (!myToko.toko) {
      onGetMyToko();
    }
  }, []);

  const updateReloadProfile = (status: boolean) => {
    setReloadProfile(status);
  };

  const updateProfile = (data: Admin.MyData) => {
    dispatch(saveUserData(data));
  };

  const updateReward = (data: Admin.Reward[]) => {
    setReward(data);
  };
  const updateReloadReward = (status: boolean) => {
    setReloadReward(status);
  };

  const updateCommission = (data: Admin.CommisionFinancial) => {
    setCommission(data);
  };
  const updateReloadCommission = (status: boolean) => {
    setReloadCommission(status);
  };

  const updateRewardSummary = (data: Admin.RewardSummary) => {
    setRewardSummary(data);
  };
  const updateReloadRewardSummary = (status: boolean) => {
    setReloadRewardSummary(status);
  };

  const updateReferral = (data: Admin.ReferralDetail) => {
    setReferral(data);
  };
  const updateReloadReferral = (status: boolean) => {
    setReloadReferral(status);
  };

  const updatePinStep = (step: number) => {
    setPinChangeStep(step);
  };

  const updatePins = (pin: string) => {
    const temp = [...pins];
    temp[pinChangeStep - 1] = pin;
    setPins(temp);
  };

  return {
    profile,
    reloadProfile,
    commission,
    reloadCommission,
    rewardSummary,
    reloadRewardSummary,
    referral,
    reloadReferral,
    myToko,
    pinChangeStep,
    pins,
    reward,
    reloadReward,
    kycStatus,
    holdSaldo,
    holdSaldoList,
    onUpdatePIN,
    updateReloadProfile,
    updateCommission,
    updateReloadCommission,
    updateRewardSummary,
    updateReloadRewardSummary,
    updateReferral,
    updateReloadReferral,
    updatePinStep,
    updatePins,
    updateProfile,
    updateReward,
    updateReloadReward,
    // API
    onGetHoldSaldoList,
    onGetHoldSaldo,
    onGetProfile,
    onGetCommission,
    onGetRewardSummary,
    onGetReward,
    onGetReferral,
    onRequestOtp,
    onVerifyOtp,
    onGetKYCStatus,
    onPostKYC,
    isLoadingRequestOtp,
    isLoadingVerifyOtp,
    isLoadingKYCStatus,
    isLoadingKYC,
    onGetMyToko,
    onUpdateUserData,
    loginMode,
    onVerifyEmailPhoneOtp,
    isSuccessVerifyEmailPhone,
    isloadingVerifyEmailPhone,
    onGetResellerHistory,
    isLoadingResellerHistory,
    resellerHistory,
    isLoadingMyToko,
  };
};

export default useProfile;
